import styled from "@emotion/styled";
import React from "react";

interface ResponsiveProps {
    sm?: React.ReactNode;
    md: React.ReactNode;
    lg?: React.ReactNode;
    tw?: string;
}

const Responsive: React.FC<ResponsiveProps> = ({ sm, md, lg }) => {
    return (
        <>
            <Sm>{sm || md}</Sm>
            <Md>{md}</Md>
            <Lg>{lg || md}</Lg>
        </>
    );
};

export { Responsive };

const Sm = styled.div`
    display: none;
    @media (max-width: 1224px) {
        display: block;
    }
`;

const Md = styled.div`
    display: none;
    @media (min-width: 1225px) and (max-width: 1800px) {
        display: block;
    }
`;

const Lg = styled.div`
    display: none;
    @media (min-width: 1801px) {
        display: block;
    }
`;
