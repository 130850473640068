import { atom } from "recoil";

export const isBookAppointmentOpenState = atom<boolean>({
    key: "isBookAppointmentOpenState",
    default: false,
});

export const whiteUiState = atom<boolean>({
    key: "whiteUiState",
    default: false,
});
