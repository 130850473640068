const theme = {
    colors: {
        black: "#000",
        paleGray: "#F1EDE7",
        lightGray: "#F1EEE5",
        gray: "#DDDBCE",
        pistacio: "#C5CAAF",
    },
};

export { theme };
